import Header from "../../shared-components/header/Header";
import AudioDescription from "./audio-description/AudioDescription";
import styles from './ASMR.module.scss';
import {audios} from './audios';
import Footer from "../../shared-components/footer/Footer";
const ASMR = () => {
  return (
    <div>
      <Header className="headerBorder" />
      <div className={styles.coverPage}>
        <div className={styles.container}>
          <h2>ASMR</h2>
            <p>Desfrute de uma experiência única e personalizada combinando diversos áudios,
                reproduzindo-os simultaneamente e ajustando o volume individualmente.
                Crie a atmosfera perfeita para atender às suas necessidades e elevar sua experiência.</p>
        </div>
      </div>
      <div className={styles.content}>
        <div style={{display: 'flex', gap: 32, flexWrap: 'wrap', justifyContent: 'center'}}>
            {audios.map((audio, index) => <AudioDescription key={index} name={audio.name} url={audio.url}  />)}
        </div>
      </div>
        <Footer/>
    </div>
  );
};

export default ASMR;
