export const audios = [
    // { name: 'Avião', slug: 'airplane', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/airplane/airplane.m3u8' },
    { name: 'Fogueira', slug: 'bonfire', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/bonfire/bonfire.m3u8' },
    { name: 'Ruído marrom', slug: 'brownnoise', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/brownnoise/brownnoise.m3u8' },
    // { name: 'Bolhas', slug: 'bubbles', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/bubbles/bubbles.m3u8' },
    // { name: 'Cigarras', slug: 'cicadas', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/cicadas/cicadas.m3u8' },
    // { name: 'Paisagem urbana', slug: 'cityscape', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/cityscape/cityscape.m3u8' },
    { name: 'Cafeteria', slug: 'coffeeshop', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/coffeeshop/coffeeshop.m3u8' },
    { name: 'Ventilador', slug: 'fan', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/fan/fan.m3u8' },
    // { name: 'Lareira', slug: 'fireplace', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/fireplace/fireplace.m3u8' },
    // { name: 'Floresta', slug: 'forest', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/forest/forest.m3u8' },
    { name: 'Folhas', slug: 'leaves', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/leaves/leaves.m3u8' },
    // { name: 'Ondas do oceano', slug: 'oceanwaves', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/oceanwaves/oceanwaves.m3u8' },
    { name: 'Ruído rosa', slug: 'pinknoise', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/pinknoise/pinknoise.m3u8' },
    { name: 'Chuva', slug: 'rain', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/rain/rain.m3u8' },
    // { name: 'Chuva na barraca', slug: 'rainontent', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/rainontent/rainontent.m3u8' },
    { name: 'À beira-mar', slug: 'seaside', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/seaside/seaside.m3u8' },
    // { name: 'Motor espacial', slug: 'spaceengine', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/spaceengine/spaceengine.m3u8' },
    { name: 'Noite de Verão', slug: 'summernight', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/summernight/summernight.m3u8' },
    { name: 'Trovoada', slug: 'thunderstorm', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/thunderstorm/thunderstorm.m3u8' },
    { name: 'Trem', slug: 'train', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/train/train.m3u8' },
    // { name: 'Floresta tropical', slug: 'tropicalforest', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/tropicalforest/tropicalforest.m3u8' },
    // { name: 'Embaixo da agua', slug: 'underwater', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/underwater/underwater.m3u8' },
    // { name: 'Máquina de lavar', slug: 'washingmachine', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/washingmachine/washingmachine.m3u8' },
    { name: 'Água', slug: 'water', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/water/water.m3u8' },
    // { name: 'Cachoeira', slug: 'waterfall', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/waterfall/waterfall.m3u8' },
    { name: 'Corrente de água', slug: 'waterstream', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/waterstream/waterstream.m3u8' },
    { name: 'Ruído branco', slug: 'whitenoise', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/whitenoise/whitenoise.m3u8' },
    { name: 'Vento', slug: 'wind', url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/asmr/wind/wind.m3u8' },
]
