import styles from './AudioDescription.module.scss'
import AudioPlayer from "../../../shared-components/audio-player/AudioPlayer";
export const AudioDescription = ({url, name, description, tag}) => {
    return (
        <div className={styles.root}>
            {url && <AudioPlayer url={url} />}
            <div className={styles.wrapper}>
                <div>
                    <p className={styles.audioTitle}>{name}</p>
                    <p className={styles.audioDescription}>{description}</p>
                </div>
                <span className={styles.tag}>{tag}</span>
            </div>
        </div>
    )
}

export default AudioDescription;