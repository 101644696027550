import {
    RouterProvider,
    createBrowserRouter
} from "react-router-dom";
import Home from "../pages/home/Home";
import HipnoseAudioList from '../pages/hipnose-audio-list/HipnoseAudioList';
import ASMR from "../pages/asmr/ASMR";
import RequestAudio from "../pages/request-audio/RequestAudio";
import About from "../pages/about/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/sobre",
    element: <About />,
  },
  {
    path: "/hipnose-guiada",
    element: <HipnoseAudioList />,
  },
  {
    path: "/solicitar-audio-personalizado",
    element: <RequestAudio />,
  },
  {
    path: "/asmr",
    element: <ASMR />,
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
