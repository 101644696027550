import ReactPlayer from "react-player";
import styles from './AudioPlayer.module.scss';
import {createRef, useEffect, useState} from "react";
import clsx from "clsx";
import {useUser} from "@supabase/auth-helpers-react";
import {useLoginDispatch} from "../login/LoginProvider";

const AudioPlayer = ({ showVolume, url }) => {
    const user = useUser();
    const loginDispatch = useLoginDispatch();
    const player = createRef();
    const [state, setState] = useState({
        url,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: true
    })
    const onPlay = () => {
        if (!user) {
            loginDispatch({type: 'showModal', payload: true});
            return;
        }
        setState((state) => ({...state, playing: !state.playing, url: state.playing ? null : url}))
    }

    // const handleSeekMouseDown = e => {
    //     setState((state) => ({ ...state, seeking: true }))
    // }
    //
    // const handleSeekChange = e => {
    //     setState((state) => ({ ...state, played: parseFloat(e.target.value) }))
    // }
    //
    // const handleSeekMouseUp = e => {
    //     setState((state) => ({ ...state, seeking: false }))
    //     player.current.seekTo(parseFloat(e.target.value))
    // }

    const handleProgress = state => {
        if (!state.seeking) {
            setState((currentState) => ({...currentState, ...state}))
        }
    }

    const handleVolumeChange = e => {
        setState((state) => ({ ...state, volume: e.target.value }))
    }

    return (
        <div className={styles.root}>
            <button className={clsx(styles.playerButton, {[styles.play]: !state.playing, [styles.stop]: state.playing})} onClick={onPlay} />
            <ReactPlayer
                style={{display: 'none'}}
                width={0}
                height={0}
                ref={player}
                url={state.url}
                playing={state.playing}
                onSeek={e => false}
                onEnded={() => setState((state) => ({...state, playing: false}))}
                onProgress={handleProgress}
                loop={true}
                volume={state.volume}
                // onDuration={(e) => console.log('duration: ', e)}
             />
            {
                showVolume && (
                    <input type='range' min={0} max={1} step='any' volume={state.volume} onChange={handleVolumeChange} style={{marginTop: 'auto'}} />
                )
            }
        </div>
    )
}

export default AudioPlayer;