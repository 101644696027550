import styles from './Menu.module.scss';
import {ReactComponent as Logo } from "../../assets/img/logo.svg";
import clsx from "clsx";
import {Link} from "react-router-dom";

const Menu = ({open, onClose}) => {
    return (
        <div className={clsx(styles.root, {[styles.open]: open})}>
            <button className={styles.close} onClick={onClose}>X</button>
            <div>
                <Logo width={120} />
            </div>
            <ul>
                <li>
                    <Link to="/sobre">
                        Sobre
                    </Link>
                </li>
                <li>
                    <Link to="/hipnose-guiada">
                        Hipnose Guiada
                    </Link>
                </li>
                <li>
                    <Link to="/asmr">
                        ASRM
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Menu;