import Routes from "../routes/Routes";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import { LoginProvider } from "../shared-components/login/LoginProvider";
import Login from "../shared-components/login/Login";
const Startup = () => {
    const supabaseClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_ANON_KEY,
        {
            auth: {
            autoRefreshToken: true,
            persistSession: true,
            storageKey: "sb.token",
            },
        }
    );
  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
        <LoginProvider>
            <Routes />
            <Login />
        </LoginProvider>
    </SessionContextProvider>
  );
};

export default Startup;
