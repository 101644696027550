import * as React from 'react';

const initialValue = {
    showModal: false
};

const LoginStateContext = React.createContext(undefined);
const LoginDispatchContext = React.createContext(undefined);

function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'showModal':
            return {...state, showModal: payload}
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
}

export function LoginProvider({ children }) {
    const [state, dispatch] = React.useReducer(reducer, initialValue);
    return (
        <LoginStateContext.Provider value={state}>
            <LoginDispatchContext.Provider value={dispatch}>
                {children}
            </LoginDispatchContext.Provider>
        </LoginStateContext.Provider>
    );
}

export function useLoginState() {
    const context = React.useContext(LoginStateContext);
    if (context === undefined) {
        throw new Error('useLoginState must be used within a LoginProvider');
    }
    return context;
}

export function useLoginDispatch() {
    const context = React.useContext(LoginDispatchContext);
    if (context === undefined) {
        throw new Error('useLoginDispatch must be used within a LoginProvider');
    }
    return context;
}