import { Link } from "react-router-dom";
import Header from "../../shared-components/header/Header";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.content}>
        <h1 className="title-extra">Conquiste equilíbrio e bem-estar</h1>
        <p className="subtitle">
          Transforme sua rotina com auxílio da esurtei!
        </p>
        <small>#rotina>motivação</small>
        <div className={styles.buttonWrapper}>
            <Link to="/hipnose-guiada">
                Hipnose Guiada
            </Link>
            <Link to="/asmr">
                ASMR
            </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
