import Header from "../../shared-components/header/Header";
import styles from './RequestAudio.module.scss'
const RequestAudio = () => {
    return (
        <div className={styles.root}>
            <Header />
            <iframe src="https://n4kful1zj41.typeform.com/to/oMmn1aTw" />
        </div>
    )
}

export default RequestAudio;