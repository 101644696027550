import Header from "../../shared-components/header/Header";
import AudioDescription from "./audio-description/AudioDescription";
import styles from './HipnoseAudioList.module.scss';
import BrainHeadset from '../../assets/img/brain_headset.png';
import {Link} from "react-router-dom";
import { freeAudioList } from './freeAudioList';
import Footer from "../../shared-components/footer/Footer";
import {protocolList} from "./protocolList";

const HipnoseAudioList = () => {
  return (
      <div>
        <Header className="headerBorder"/>
        <div className={styles.coverPage}>
          <div className={styles.container}>
            <h2>Domine sua mente com a Hipnose Guiada</h2>
            <p>Mergulhe nos áudios e experimente transformações incríveis em sua vida!</p>
          </div>
        </div>
        <div className={styles.content}>
          <div style={{gap: 16, display: 'flex', flexDirection: 'column'}}>
            {
              freeAudioList.map((audio, index) => (
                  <AudioDescription key={index} name={audio.name} description={audio.description} tag={audio.tag}
                                    url={audio.url}/>
              ))
            }
          </div>
          <div className={styles.myAudios}>
            <h2 className="title">Meus áudios</h2>
            <div className={styles.withOutAudio}>
              <img src={BrainHeadset} alt="esurtei" width={140}/>
              <div>
                <p className={styles.withOutAudio_title}>Que tal um áudio personalizado só seu?<br/> Conquiste resultados incríveis!</p>
                <Link className={styles.withOutAudio_button} to="/solicitar-audio-personalizado">Quero ter um áudio
                  personalizado!</Link>
              </div>
            </div>
          </div>
          <div>
            <h2 className="title">Protocolos esurtei</h2>
            <div className={styles.protocolList}>
                {
                    protocolList.map((audio, index) => (
                        <AudioDescription key={index} name={audio.name} description={audio.description} tag={audio.tag}
                                          url={audio.url}/>
                    ))
                }
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
};

export default HipnoseAudioList;
