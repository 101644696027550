import {
    useSupabaseClient, useUser,
} from "@supabase/auth-helpers-react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { locationLabels } from "./locationLabels";
import styles from './Login.module.scss';
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import {useLoginDispatch, useLoginState} from "./LoginProvider";
import {useEffect} from "react";

const Login = () => {
    const user = useUser();
    const loginDispatch = useLoginDispatch();
    const supabaseClient = useSupabaseClient();
    const {showModal} = useLoginState();
    useEffect(() => {
        if (user) {
            loginDispatch({type: 'showModal', payload: false})
        }
    }, [user])
    if (!showModal) return null;
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <Logo />
                <p>Acesse sua conta esurtei ou cadastre-se</p>
                <div className={styles.formAuth}>
                    <Auth
                        supabaseClient={supabaseClient}
                        providers={[]}
                        redirectTo={`${window.location.href}`}
                        magicLink={true}
                        localization={{
                            variables: {
                                ...locationLabels
                            },
                        }}
                        appearance={{
                            theme: ThemeSupa,
                            variables: {
                                default: {
                                    colors: {
                                        brand: "#404040",
                                        brandAccent: "#52525b",
                                    },
                                },
                            },
                        }}
                        theme="light"
                    />
                </div>
            </div>
        </div>
    )
}

export default Login;