import styles from './Footer.module.scss'
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { ReactComponent as LogoInstagram } from '../../assets/icons/instagram.svg';

const Footer = () => {
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.logoWrapper}>
                    <Logo className={styles.logo} width={90} />
                </div>
                <hr />
                <div className={styles.poweredByWrapper}>
                    <p className={styles.poweredBy}>Feito com 💜 por Marcus Araujo</p>
                    <a href="https://www.instagram.com/_esurtei/" rel="noreferrer nofollow" target="_blank" className={styles.followInsta}>
                        <LogoInstagram  />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;