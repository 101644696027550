import styles from './Header.module.scss';
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import MenuImg from '../../assets/img/menu.png';
import Menu from "../menu/Menu";
import {useState} from "react";

const Header = (props) => {
    const {className, ...restProps} = props;
    const [openMenu, setOpenMenu] = useState(false);
    return (
        <>
            <div {...restProps} className={[styles.root, className].join(' ')}>
                <Logo />
                <button className={styles.menuButton} onClick={() => setOpenMenu(true)}>
                    <img src={MenuImg} alt="" />
                </button>
            </div>
            <Menu open={openMenu} onClose={() => setOpenMenu(false)} />
        </>
    );
};

export default Header;
