import Header from "../../shared-components/header/Header";
import MarcusAraujo from '../../assets/img/marcos.jpg';
import styles from './About.module.scss'
import Footer from "../../shared-components/footer/Footer";

const About = () => {
  return (
      <div className={styles.root}>
        <Header />
          <div className={styles.content}>
              <img src={MarcusAraujo} alt="Marcus Araujo" />
              <div>
                  <p>
                      A Esurtei é uma plataforma criada pelo terapeuta Marcus
                      Araujo, na qual você encontra áudios de hipnose guiada, ASMR
                      (Resposta Sensorial Meridiana Autônoma), protocolos e ferramentas para auxiliar no seu bem-estar
                      emocional e mental.
                  </p>
                  <p>
                      A plataforma tem como objetivo ajudar os usuários a
                      enfrentar desafios diários, combater o estresse, a ansiedade e
                      melhorar a qualidade de vida.
                  </p>
                  <p>
                      Os áudios de hipnose guiada são projetados para induzir um estado de
                      relaxamento profundo, permitindo que os usuários acessem seu
                      subconsciente e trabalhem questões emocionais e comportamentais.
                  </p>
                  <p>
                      Já os áudios de ASMR proporcionam uma sensação de relaxamento e
                      prazer por meio de estímulos sonoros, como sons suaves e movimentos lentos.
                      Além disso, o ASMR pode auxiliar na melhora do foco e concentração durante períodos de estudo ou
                      trabalho, tornando-se um recurso útil para aumentar a produtividade.
                  </p>
                  <p>
                      Com a Esurtei, os usuários podem incorporar práticas de relaxamento e
                      gestão emocional em suas rotinas diárias, contribuindo para uma vida
                      mais equilibrada e saudável.
                  </p>
                  <p>No entanto, é importante lembrar que,
                      embora a plataforma possa ser um recurso útil, ela não substitui o
                      acompanhamento profissional de um terapeuta ou um médico em casos de
                      problemas emocionais ou psicológicos graves.</p>
                  <p>
                      Para saber mais sobre o meu trabalho, acesse o site <strong><a href="https://www.tbch.com.br" target="_blank">tbch.com.br</a></strong>
                  </p>
              </div>
          </div>
          <Footer />
      </div>
  );
};

export default About;
