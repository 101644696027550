export const locationLabels = {
    "sign_up": {
        "email_label": "Endereço de email",
        "password_label": "Criar uma senha",
        "email_input_placeholder": "Seu endereço de email",
        "password_input_placeholder": "Sua senha",
        "button_label": "Cadastre-se",
        "loading_button_label": "Cadastrando...",
        "social_provider_text": "Entrar com {{provider}}",
        "link_text": "Não tem uma conta? ",
        "confirmation_text": "Verifique seu e-mail com o link de confirmação"
    },
    "sign_in": {
        "email_label": "Endereço de email",
        "password_label": "Sua senha",
        "email_input_placeholder": "Seu endereço de email",
        "password_input_placeholder": "Sua senha",
        "button_label": "Entrar",
        "loading_button_label": "Entrando ...",
        "social_provider_text": "Entrar com {{provider}}",
        "link_text": "já tem uma conta? "
    },
    "magic_link": {
        "email_input_label": "Endereço de e-mail",
        "email_input_placeholder": "Seu endereço de e-mail",
        "button_label": "Enviar link para acesso com e-mail",
        "loading_button_label": "Enviando...",
        "link_text": "Acessar usando apenas e-mail",
        "confirmation_text": "Verifique seu e-mail"
    },
    "forgotten_password": {
        "email_label": "Endereço de email",
        "password_label": "Sua senha",
        "email_input_placeholder": "Seu endereço de email",
        "button_label": "Enviar instruções de redefinição de senha",
        "loading_button_label": "Enviando instruções de reinicialização...",
        "link_text": "Esqueceu sua senha?",
        "confirmation_text": "Verifique seu e-mail com o link de redefinição de senha"
    },
    "update_password": {
        "password_label": "Nova Senha",
        "password_input_placeholder": "Sua nova senha",
        "button_label": "Atualizar senha",
        "loading_button_label": "Atualizando senha...",
        "confirmation_text": "Sua senha foi atualizada"
    }
}