export const freeAudioList = [
    {
        name: 'Medo de dirigir',
        description: 'Liberte-se das inseguranças e ansiedades ao volante, ganhe confiança e torne-se um motorista mais tranquilo e seguro.',
        tag: 'Gratuito',
        url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/Ii2y_zQSVyKQ/Ii2y_zQSVyKQ.m3u8'
    },
    {
        name: 'Enxaqueca',
        description: 'Aprenda a gerenciar a dor, relaxar a mente e o corpo, e reduzir a frequência e intensidade das crises.',
        tag: 'Gratuito',
        url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/b3t79xOzBDW8/b3t79xOzBDW8.m3u8'
    },
    {
        name: 'Áudio para dormir',
        description: 'Relaxe profundamente, acalme sua mente e prepare-se para uma jornada rumo ao sono tranquilo e restaurador.',
        tag: 'Gratuito',
        url: 'https://fmgqtatsnxrmzttcdrtr.supabase.co/storage/v1/object/public/audios/I8fYbrhe4zhS/I8fYbrhe4zhS.m3u8'
    },
]