export const protocolList = [
    {
        name: 'Emagrecimento',
        description: 'Alcance seu peso ideal através de escolhas saudáveis e transformação do estilo de vida.',
        tag: 'Em breve',
        url: null
    },
    {
        name: 'Tabagismo',
        description: 'Liberte-se do vício e adote um estilo de vida mais saudável e livre de fumaça.',
        tag: 'Em breve',
        url: null
    },
    {
        name: 'Ansiedade',
        description: 'Encontre equilíbrio emocional e tranquilidade para superar momentos de preocupação e inquietação.',
        tag: 'Em breve',
        url: null
    },
    { name: 'Estresse', description: 'Cultive a calma e a resiliência para enfrentar e gerenciar situações desafiadoras com serenidade.', tag: 'Em breve', url: null },
    { name: 'Insônia', description: 'Conquiste um sono profundo e reparador para acordar revigorado e pronto para enfrentar o dia.', tag: 'Em breve', url: null },
    { name: 'Autoconfiança', description: 'Desenvolva a autoestima e o amor-próprio para encarar desafios e oportunidades com segurança.', tag: 'Em breve', url: null },
    { name: 'Procrastinação', description: 'Supere a tendência de adiar tarefas e aumente sua produtividade e eficiência.', tag: 'Em breve', url: null },
    { name: 'Concentração e foco', description: 'Melhore sua habilidade de se concentrar e manter o foco para obter melhores resultados.', tag: 'Em breve', url: null },
    { name: 'Gerenciamento de raiva', description: 'Desenvolva habilidades de controle emocional e resolução de conflitos para lidar com a raiva de forma saudável.', tag: 'Em breve', url: null },
]




